button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Main container for each image preview and buttons */
.image-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* Container for images and "Add Another Image" button */
.image-upload-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-top: 15px;
  width: 100%;
}

/* Image preview styling */
.image-preview {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-thumbnail,
.image-placeholder {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 8px;
}

/* Remove button styling */
.remove-image-button {
  background-color: #ff4d4d;
  color: white;
  padding: 8px 12px;
}

.remove-image-button:hover {
  background-color: #ff3333;
}

/* Add Another Image button styling */
.add-image-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 16px;
  margin-top: 12px;
}

.add-image-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #9e9e9e;
  color: white;
  margin-left: 10px;
}

.edit-job {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.form-title {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 2rem;
  text-align: center;
}

.form-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #4a5568;
  font-size: 1rem;
}

/* Input fields */
.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

.input-field:focus {
  outline: none;
  border-color: #27ae60;
  box-shadow: 0 0 0 3px rgba(39, 174, 96, 0.1);
}

/* Textarea styling */
.textarea-field {
  width: 100%;
  min-height: 120px;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  resize: vertical;
  transition: border-color 0.2s ease;
}

.textarea-field:focus {
  outline: none;
  border-color: #27ae60;
  box-shadow: 0 0 0 3px rgba(39, 174, 96, 0.1);
}

.submit-button {
  background-color: #27ae60;
  color: white;
  padding: 0.875rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  min-width: 200px;
  box-shadow: 0 2px 4px rgba(39, 174, 96, 0.2);
  cursor: pointer;
}

.submit-button:hover {
  background-color: #219a52;
}

.submit-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

/* Error message styling */
.error {
  color: #e53e3e;
  background-color: #fff5f5;
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
}

.form-section {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.section-title {
  font-size: 1.125rem;
  color: #2d3748;
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e2e8f0;
}

.date-input:focus {
  outline: none;
  border-color: #27ae60;
  box-shadow: 0 0 0 3px rgba(39, 174, 96, 0.1);
}

.input-readonly {
  background-color: #f7fafc;
  cursor: not-allowed;
}

/* Services grid */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-label {
  font-size: 0.875rem;
  color: #4a5568;
}

.checkbox-input {
  width: 1rem;
  height: 1rem;
  border: 2px solid #27ae60;
  border-radius: 3px;
  cursor: pointer;
}

/* Image upload section */
.image-upload-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.image-upload-container {
  margin-bottom: 1rem;
}

/* File input button styling */
.file-input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.file-input::file-selector-button {
  background-color: #27ae60;
  color: white;
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 1rem;
  transition: all 0.2s ease;
}

.file-input::file-selector-button:hover {
  background-color: #219a52;
  transform: translateY(-1px);
}

/* Loading state */
.submitting {
  position: relative;
  background-color: #27ae60;
  color: transparent;
}

.submitting::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-section {
    padding: 1rem;
  }

  .services-grid,
  .image-upload-grid {
    grid-template-columns: 1fr;
  }

  .file-input::file-selector-button {
    padding: 0.5rem 1rem;
    font-size: 0.813rem;
  }

  .submit-button {
    padding: 0.75rem 1.5rem;
    font-size: 0.938rem;
    min-width: 180px;
  }

  .remove-image-btn {
    padding: 0.375rem 0.75rem;
    font-size: 0.813rem;
  }
}

/* Increased font size for labels on mobile */
@media (max-width: 480px) {
  .form-group label {
    font-size: 1.1rem;
    color: #333;
    font-weight: 600;
  }

  .edit-job-content {
    padding: 1rem;
    margin: 0 10px;
  }

  .input-field,
  .textarea-field {
    font-size: 1rem;
    padding: 0.75rem;
  }

  .submit-button,
  .add-image-button,
  .remove-image-button {
    font-size: 1rem;
    padding: 0.75rem 1rem;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .form-section {
    background-color: #2d3748;
  }

  .section-title {
    color: #e2e8f0;
    border-bottom-color: #4a5568;
  }

  .form-group label {
    color: #e2e8f0;
  }

  .input-field,
  .input-readonly,
  .date-input,
  .textarea-field {
    background-color: #4a5568;
    border-color: #718096;
    color: #e2e8f0;
  }

  .input-readonly {
    background-color: #2d3748;
  }

  .checkbox-label {
    color: #e2e8f0;
  }

  .file-input::file-selector-button,
  .submit-button,
  .remove-image-btn {
    background-color: #2ecc71;
  }

  .file-input::file-selector-button:hover,
  .submit-button:hover,
  .remove-image-btn:hover {
    background-color: #27ae60;
  }

  .submit-button:disabled {
    background-color: #4a5568;
  }
}
