.notes-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9; /* Soft background color */
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.notes-container:hover {
    transform: translateY(-4px); /* Subtle hover effect */
}

.notes-textarea,
.notes-email,
.notes-date {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    margin-bottom: 15px;
    font-family: inherit;
    transition: border-color 0.2s, box-shadow 0.2s;
}

.notes-textarea:focus,
.notes-email:focus,
.notes-date:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
}

.notes-button {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.notes-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.notes-button:not(:disabled):hover {
    background-color: #45a049;
    transform: scale(1.02);
}

.status-message {
    margin-top: 15px;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    animation: fade-in 0.5s ease;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.notes-date {
    cursor: pointer;
    background-color: #ffffff;
    transition: box-shadow 0.2s ease;
}

.notes-date:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.status-message.success {
    background-color: #e8f5e9;
    color: #2e7d32;
    border: 1px solid #c8e6c9;
}

.status-message.error {
    background-color: #ffebee;
    color: #c62828;
    border: 1px solid #ffcdd2;
}

@media (max-width: 768px) {
    .notes-container {
        padding: 16px;
        margin: 10px;
    }

    .notes-button {
        font-size: 14px;
        padding: 12px;
    }

    .notes-textarea {
        height: 40vh;
    }
}
/* Autosuggest container */
.react-autosuggest__container {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

.react-autosuggest__input {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    font-family: inherit;
    transition: border-color 0.2s, box-shadow 0.2s;
}

.react-autosuggest__input:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
}

.react-autosuggest__suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-autosuggest__suggestions-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.react-autosuggest__suggestion {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #e8f5e9; /* Light green highlight */
    color: #2e7d32;
}

