/* Global Box-Sizing and Overflow Control */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
  width: 100%;
}

.delete-service-button {
  margin-left: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.delete-service-button:hover {
  background-color: darkred;
}

.delete-job-button {
  background-color: red;
  color: white;
  padding: 12px; /* Match the padding of Edit and Send Email buttons */
  border-radius: 5px; /* Match the border radius */
  border: none;
  font-size: 1rem; /* Match the font size */
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  flex: 1; /* Ensure uniform sizing with other buttons */
  margin: 0; /* Remove any margin to match */
}

.delete-job-button:hover {
  background-color: darkred;
}


/* Main Container for Job Details */
.job-details-container {
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px 40px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
}

/* Job Header */
.job-details-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
  padding-bottom: 15px;
  position: relative;
  flex-wrap: wrap; /* Allows elements to wrap on small screens */
}

.job-details-header h1 {
  font-size: 1.8rem;
  color: #007820;
  margin: 0;
  text-align: center;
  flex: 1;
}

.job-details-header p {
  font-size: 1rem;
  color: #666;
}

/* Back Button */
.back-button {
  position: absolute;
  left: 10px;
  background: none;
  border: none;
  color: #007820;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 8px 0;
  transition: color 0.2s ease;
}

.back-button:hover {
  color: #005bb5;
}

/* Date Display */
.job-date {
  color: #999;
  font-size: 0.95rem;
  margin-top: 5px;
}

/* Main Image */
.job-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

/* Image Grid for Thumbnails */
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin: 20px 0;
  width: 100%;
}

/* Image Container */
.image-container img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 6px;
  transition: transform 0.2s ease;
}

.image-container:hover img {
  transform: scale(1.05);
}

/* Details Sections */
.details-section {
  margin-top: 20px;
  max-width: 100%;
}

.details-section h2 {
  font-size: 1.4rem;
  color: #444;
  margin-bottom: 10px;
}

.details-section p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

/* Services List */
.services-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.service-item {
  background: #e0f4ea;
  color: #007820;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.95rem;
}

/* Notes Section */
.notes-section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 1rem;
  color: #444;
  line-height: 1.5;
  max-width: 100%;
}

/* Button Group */
.job-details-buttons {
  display: flex;
  gap: 15px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.job-details-buttons button {
  flex: 1;
  padding: 12px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

/* Edit and Send Email Buttons */
.edit-job-button, .send-email-button {
  background-color: #28af60;
  color: white;
}

.edit-job-button:hover, .send-email-button:hover {
  background-color: #219653;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .job-details-container {
    padding: 20px 30px;
  }

  .job-details-header h1 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .job-details-container {
    padding: 15px 20px;
  }

  .job-details-header {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 0 10px;
  }

  .job-details-header h1 {
    font-size: 1.4rem;
    text-align: center;
  }

  .back-button {
    position: relative;
    order: -1;
    left: 0;
    margin-bottom: 10px;
  }

  .job-details-buttons {
    flex-direction: column;
  }

  .image-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

@media (max-width: 480px) {
  .job-details-container {
    padding: 10px 15px;
    margin: 20px 10px;
  }

  .job-details-header h1 {
    font-size: 1.2rem;
  }

  .job-details-header p {
    font-size: 0.9rem;
  }

  .job-image {
    max-height: 300px;
  }

  .details-section h2 {
    font-size: 1.2rem;
  }

  .details-section p,
  .job-details-header p,
  .job-date {
    font-size: 0.9rem;
  }

  .image-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .job-details-buttons button {
    padding: 10px;
    font-size: 0.9rem;
  }
}
