.user-management {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h2 {
    text-align: center;
}

.add-user-btn {
    display: block;
    margin: 10px auto;
    padding: 8px 16px;
    background-color: #296b1a;
    color: white;
    border: none;
    cursor: pointer;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.user-table th, .user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.user-table th {
    background-color: #f4f4f4;
}

.edit-btn, .delete-btn {
    margin: 2px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.edit-btn {
    background-color: green;
    color: white;
}

.delete-btn {
    background-color: red;
    color: white;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
}

.modal-form label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
}


.modal-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.modal-input:focus {
    border-color: #007bff;
}

.modal-submit-btn {
    background: #296b1a;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease-in-out;
    margin-top: 10px;
}

.modal-submit-btn:hover {
    background: darkgreen;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    color: red;
    font-size: 20px;
    cursor: pointer;
}

.line-chart {
    margin-top: 20px;
    width: 100%;
    height: 400px;
}


.close-btn:hover {
    color: darkred;
}