/* Input and Textarea Styles */
.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.2s ease;
    color: #333;
    background-color: #f9f9f9;
    word-wrap: break-word; 
}

#cc {
    width: 100%; /* Ensure full width */
    height: auto; /* Automatically adjust height */
    min-height: 4rem; /* Minimum height for better visibility */
    resize: vertical; /* Allow users to resize the field vertically */
    overflow-wrap: break-word; /* Ensure long emails wrap correctly */
    white-space: pre-wrap; /* Keep email addresses readable and wrapped */
    display: block; /* Ensure consistent block styling */
}
/* Green Primary Button */
.close-modal {
    padding: 0.75rem 1.5rem;
    background-color: #007820; /* Primary green color */
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 120, 32, 0.2);
}

.close-modal:hover:not(.disabled) {
    background-color: #005c18; /* Darker green on hover */
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 120, 32, 0.3);
}

.close-modal:disabled {
    background-color: #88b794; /* Lighter green for disabled state */
    cursor: not-allowed;
    box-shadow: none;
}


.form-control:focus {
    border-color: #007820;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 120, 32, 0.3);
}

/* Label Styling */
.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #333;
}

/* "Attachment" and "Preview PDF" Styling */
.form-group p {
    margin: 0.5rem 0 0;
    color: #4a5568;
    font-size: 0.9rem;
}

.pdf-download-link {
    display: inline-block;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    color: #007820;
    background: #f0fff4;
    border: 1px solid #007820;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
}

.pdf-download-link:hover {
    background: #e6f5ec;
    color: #005c18;
}

/* Message Styling */
.message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.95rem;
    background-color: #f0fff4;
    color: #2f855a;
    text-align: center;
}

.message.error {
    background-color: #fff5f5;
    color: #e53e3e;
}

.message.success {
    background-color: #f0fff4;
    color: #2f855a;
}

/* Adjustments for Buttons */
.primary-button,
.secondary-button {
    font-size: 1rem;
}

/* Animation and Transformation for Hover Effects */
.primary-button:hover:not(.disabled),
.secondary-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 120, 32, 0.2);
}

/* Mobile Responsiveness */
@media (max-width: 480px) {
    .form-control {
        font-size: 0.9rem;
    }
    
    .pdf-download-link,
    .primary-button,
    .secondary-button {
        font-size: 0.9rem;
    }
}
