/* AdminTaskList.css */
.admin-task-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    justify-content: center;
  }
  
  .admin-task-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    max-width: 320px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .admin-task-card h3 {
    margin: 0 0 8px;
    font-size: 18px;
    color: #333;
  }
  
  .admin-task-detail {
    margin-top: 12px;
    padding: 8px;
    border-top: 1px solid #e0e0e0;
  }
  
  .admin-task-image {
    margin-top: 8px;
    width: 100%;
    border-radius: 4px;
  }
  